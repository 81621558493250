import React from "react"

export default function Test(props) {
  console.log("Hello!")

  return (
    <>
      <div id="BeSoftware-Scheduler"></div>
      <script
        type="text/javascript"
        src="https://aus.iinsightonline.com/api_referral.php?COMMAND=GSC&KEY=001066ee6190c7735090d61dac574b91&FID=9a71344092c0c00e674dd50686858b03"
      ></script>
      <script type="text/javascript">Externalform.inizializate();</script>
    </>
  )
}
